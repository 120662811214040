import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`The main objective of this framework is to make creating React-based apps as fast as possible. Most of the React stack
comes preconfigured for you, and installing it all only takes a couple minutes.`}</p>
    <p>{`This document will walk you through the few steps you need to follow in order to get a basic working web application.`}</p>
    <h2 {...{
      "id": "1-project-setup"
    }}>{`1. Project Setup`}</h2>
    <p>{`You have two possibilities to setup the initial project: either manually, or using the `}<inlineCode parentName="p">{`rjs`}</inlineCode>{` cli.`}</p>
    <p>{`The easiest way is to install `}<a parentName="p" {...{
        "href": "https://github.com/reworkjs/cli"
      }}>{`@reworkjs/cli`}</a>{` globally, then run `}<inlineCode parentName="p">{`rjs init`}</inlineCode>{` at the
root of your project directory.`}</p>
    <p>{`If you do not wish to install the cli globally, you will need to manually install the framework. No worries though, it is a three-liner:`}</p>
    <ul>
      <li parentName="ul">{`Init the NPM project: `}<inlineCode parentName="li">{`npm init`}</inlineCode></li>
      <li parentName="ul">{`Install the framework: `}<inlineCode parentName="li">{`npm install --save @reworkjs/core`}</inlineCode></li>
      <li parentName="ul">{`Configure the framework: `}<inlineCode parentName="li">{`./node_modules/.bin/rjs init`}</inlineCode></li>
    </ul>
    <p>{`Even though the second method does not install the global CLI, you can still use
the local, project-specific, one available under `}<inlineCode parentName="p">{`./node_modules/.bin/rjs`}</inlineCode>{`. The rest of the documentation will assume the
global cli is available, but all used commands are fully compatible with the local cli.`}</p>
    <h2 {...{
      "id": "2-starting-your-application"
    }}>{`2. Starting your application`}</h2>
    <p>{`If you followed the first part of this tutorial, your package.json will contain a `}<inlineCode parentName="p">{`start:dev`}</inlineCode>{` script.`}</p>
    <p>{`You can from now on run `}<inlineCode parentName="p">{`npm run start:dev`}</inlineCode>{` to start the application in development mode.`}<br parentName="p"></br>{`
`}{`Alternatively you can use the `}<a parentName="p" {...{
        "href": "./cli.md"
      }}>{`rjs cli`}</a>{` directly by running `}<inlineCode parentName="p">{`NODE_ENV=development rjs start --port 3000`}</inlineCode></p>
    <h2 {...{
      "id": "3-creating-your-first-page"
    }}>{`3. Creating your first page`}</h2>
    <p>{`You app is up, but it doesn't do much currently. It's time to `}<a parentName="p" {...{
        "href": "./2-routing.md"
      }}>{`create your first page`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      